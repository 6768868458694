<template>
  <v-card color="transparent" tile elevation="0">
    <v-card class="py-sm-8 py-4" color="light_gray" tile elevation="0">
      <v-row justify="center" no-gutters>
        <v-col cols="11" lg="9">
          <span class="text-sm-h5 text-body-1 font-weight-bold">{{ $t(`label.register`) }}</span>
          <br />
          <span class="text-sm-h5 text-body-2 font-weight-bold">{{ content.className }}</span>
        </v-col>
      </v-row>
    </v-card>

    <div class="my-6 pt-8 pb-10">
      <v-row justify="center" no-gutters>
        <v-col cols="11" lg="9">
          <v-card class="px-md-6 py-6" :elevation="$vuetify.breakpoint.xsOnly ? 0 : 4">
            <p class="text-h5 full-width font-weight-bold text-center mb-0">{{ content.firstLine }}</p>
            <p class="text-h4 full-width font-weight-bold text-center mb-0">{{ content.secondLine }}</p>

            <template v-if="registrationIsOpen">
              <div class="text-center py-4">
                <img :src="content.image" class="registerClassImage" />
              </div>
              <v-form ref="registrationFormRef" @submit.prevent="onSubmitRegistration">
                <v-divider class="my-5" />
                <v-row>
                  <v-col cols="12" sm="3">
                    <div class="font-weight-bold">{{ $t('label.personalData') }}</div>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <app-form-field v-model="formData.name" :label="$t('label.fullNameAsPerNric')" :placeholder="$t('label.fullName')" :rules="$validation.nameRules()" />
                      </v-col>
                      <v-col cols="12" sm="6" md="4"><app-form-field v-model="formData.email" :label="$t('label.email')" placeholder="eg: abc@gmail.com" :rules="$validation.emailRules()" /></v-col>
                      <v-col cols="12" sm="6" md="4">
                        <app-form-field v-model="formData.contactNumber" prefix="+60" z placeholder="111 ---- ----" :label="$t('label.mobileNumber')" :rules="$validation.mobileNumberRules()" />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider class="my-5" />
                <div class="text-md-right text-center"><app-button :title="$t('label.rsvpNow')" type="default" :action="onSubmitRegistration" /></div>
              </v-form>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <Dialog :maxWidth="350" :title="dialogData.title" :dialogShow="shouldDialogShow">
      <template v-slot:body>
        <div class="dialog-body text-center">
          <p>{{ dialogData.message }}</p>
          <p v-for="(detail, i) in dialogData.details" :key="i" class="caption secondary--text mb-1">{{ detail }}</p>
          <v-btn @click="onDialogClose" class="rounded-lg font-weight-bold white--text text-body-1 px-8 mt-6" color="unbox_black" height="48px" type="button">{{ $t(`label.close`) }}</v-btn>
        </div>
      </template>
    </Dialog>
  </v-card>
</template>

<script>
import { LOAN_REGISTER_CLASS } from '@/store/loan.module'
import Dialog from '@/components/dialog/dialog.vue'
import moment from 'moment'
const eventDate = moment('2022-05-18 19:00:00')

export default {
  name: 'RegisterClass',
  components: { Dialog },
  data: () => ({
    formData: {
      name: '',
      email: '',
      contactNumber: ''
    },
    dialogData: {
      message: '',
      status: 'idle'
    },
    eventConfig: {
      className: 'Xiaomi Master Class',
      eventInfo: {
        name: 'Not to be missed! Xiaomi Master Trainer shares on getting the most of your Xiaomi products.',
        date: eventDate.format('DD MMMM YYYY'),
        time: eventDate.format('hmmA'),
        venue: 'UNBOX Sunway Geo Avenue'
      },
      eventImage: '/static/images/other/eventImage.jpg'
    }
  }),
  created() {},
  computed: {
    content() {
      if (this.registrationIsOpen) {
        return {
          className: this.eventConfig.eventInfo.name,
          firstLine: this.$t(`label.limitedSeats`),
          secondLine: this.$t(`label.secureYourSpotNow`),
          image: this.eventConfig.eventImage
        }
      }
      return {
        className: this.$t(`label.noUpcomingEvents`),
        firstLine: this.$t(`label.stayTuned`),
        secondLine: this.$t(`label.nextEventWillPostedHere`),
        image: this.eventConfig.eventImage
      }
    },
    registrationIsOpen() {
      return moment().isBefore(eventDate)
    },
    shouldDialogShow() {
      return this.dialogData.status !== 'idle'
    }
  },
  watch: {},
  methods: {
    async onSubmitRegistration() {
      let registrationResponse = await this.$store.dispatch(LOAN_REGISTER_CLASS, {
        data: {
          name: this.formData.name,
          email: this.formData.email,
          contactNumber: `60${this.formData.contactNumber}`,
          eventInfo: this.eventConfig.eventInfo
        }
      })
      if (registrationResponse.code == '0') {
        this.dialogData = {
          title: this.$t('label.registrationSuccessful'),
          message: this.$t('message.registrationSuccessfulMessage'),
          status: 'success'
        }
        this.$refs.registrationFormRef.reset()
      } else {
        const errorDetails = Object.values(registrationResponse?.errors).map((err) => err[0]) || []
        this.dialogData = {
          title: this.$t('label.registrationFailed'),
          message: this.$t('message.registrationFailed'),
          details: errorDetails,
          status: 'failed'
        }
      }
    },
    onDialogClose() {
      this.dialogData = {
        message: '',
        status: 'idle'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.event-details {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 0.5em;
  row-gap: 0.25em;
}
.registerClassImage {
  width: 100%;
  max-width: 500px;
}
</style>
